import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import useMedia from '@/compositions/media';
import {
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDefault,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemDivider,
  genMenuItemDownload,
  genMenuItemEdit,
  genMenuItemEnable,
  genMenuItemMute,
  genMenuItemShowHistory,
  genMenuItemMove,
  genMenuItemUnmute,
  hideIfEmpty
} from '@/utils/list-generators';
import { objectService } from '../api';

import router from '@/router';
import downloadBlob from '@/utils/downloadBlob';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';

export function useObjectMenu({ object }) {
  const popup = usePopup();
  const { getNotificationsHistory } = useMedia();
  const { copyWithAlert } = useCopy();
  const { id, enabled, muted, schema } = object;

  const { getNextRoute } = useHistoryStack();

  return [
    genMenuItemEdit(() => {
      popup.open({
        component: () => import('@/modules/objects/ui/ObjectEdit.vue'),
        props: {
          objectId: id
        }
      });
    }),
    genMenuItemAccess(() => {
      popup.open({
        component: () => import('@/components/popup/EditRightsGroup.vue'),
        props: {
          objectId: id
        }
      });
    }),
    ...hideIfEmpty(schema?.schemataByParentSchemaId?.length, [
      genMenuItemDefault(
        {
          id: 'state',
          icon: '$colors',
          title: 'Edit States'
        },
        {
          click: () => {
            popup.open({
              component: () =>
                import('@/modules/objects/ui/ObjectStateEdit.vue'),
              props: {
                objectId: id
              }
            });
          }
        }
      )
    ]),
    muted
      ? genMenuItemUnmute(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Unmute object?',
              onConfirm: () => objectService.setMuted(id, !muted)
            }
          });
        })
      : genMenuItemMute(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Mute object?',
              onConfirm: () => objectService.setMuted(id, !muted)
            }
          });
        }),
    enabled
      ? genMenuItemDisable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Disable object?',
              onConfirm: () => objectService.disable(id)
            }
          });
        })
      : genMenuItemEnable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Enable object?',
              onConfirm: () => objectService.enable(id)
            }
          });
        }),
    genMenuItemDivider(),
    genMenuItemMove(() => {
      router.push({
        name: 'object_moving',
        params: {
          objectId: id
        }
      });
    }),
    genMenuItemDefault(
      {
        id: 'coordinates',
        icon: '$marker_set',
        title: 'Set coordinates sources'
      },
      {
        click: () => {
          popup.open({
            component: () =>
              import('@/modules/objects/ui/ObjectCoordinateSourcesEdit.vue'),
            props: {
              objectId: id
            }
          });
        }
      }
    ),
    genMenuItemDivider(),
    genMenuItemShowHistory(async () => {
      if (router.app.$route.name !== 'object_card') {
        await router.push({
          name: 'object_card',
          params: { objectId: object.id }
        });
      }
      await router.push(
        getNextRoute({
          component: componentAliases.hec,
          props: {
            bench: 30,
            ids: [],
            monitoringItem: {
              id,
              name: object.name,
              type: 'propertyItem'
            },
            showShape: false
          }
        })
      );
    }),
    genMenuItemDownload(
      () => {
        popup.open({
          component: () =>
            import('@/components/history-list-card/HistoryDateRangePopup'),
          props: {
            onSubmit: async range => {
              const data = {
                ids: [id],
                from: `${range.from} 00:00`,
                to: `${range.to} 23:59`
              };
              getNotificationsHistory(data)
                .then(res => {
                  downloadBlob(
                    res.data,
                    `PixelMonitor_NotificationsHistory_${range.from}-${range.to}.csv`,
                    res.data.type
                  );
                })
                .catch(() => {})
                .finally(() => {});
            }
          }
        });
      },
      { title: 'Download notifications' }
    ),
    genMenuItemCopy(async () => copyWithAlert(object.id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(() => {
      popup.openConfirm({
        component: () => import('@/components/popup/PopupConfirmAction.vue'),
        props: {
          title: 'Delete object?',
          onConfirm: async () => {
            await objectService.delete(id);
            router.push({
              name: 'objects'
            });
          }
        }
      });
    })
  ];
}
